<template>
  <div class="section-content">
    <div class="content-grid">
      <div
        v-if="i18n.logo.alt"
        class="col-span-full lg:col-span-14 lg:col-start-2 text-center"
      >
        <img
          class="mx-auto logo--agency fade-in"
          :alt="`${i18n.logo.alt}`"
          :src="`${i18n.logo.sm}`"
        >
      </div>
      <div class="col-span-full px-12 sm:px-0 sm:col-span-2 sm:col-start-2 md:col-span-4 lg:col-start-2 lg:col-span-5">
        <dlp-figure
          :sm="`${i18n.photo.sm}`"
          :md="`${i18n.photo.md}`"
          :alt="`${i18n.name}`"
          :caption="`${i18n.name}`"
          ar="ar ar--4-5"
          :is-sticky="true"
          animate="shrink"
        />
      </div>
      <div class="col-span-full md:col-span-7 lg:col-start-9 lg:col-span-7">
        <section id="agent-bio">
          <h2 class="head-1 fade-in">
            {{ i18n.name }}
          </h2>
          <h4
            v-if="i18n.showAgentTitle && (i18n.title && i18n.title.length)"
            class="head-4 fade-in"
          >
            {{ i18n.title }}
          </h4>
          <p
            v-if="i18n.showDesignations && (i18n.designationList && i18n.designationList.length)"
            class="fade-in mt-2"
          >
            {{ i18n.designationList }}
          </p>
          <p
            v-if="i18n.licenses && i18n.licenses.length"
            class="mt-0"
          >
            License No. {{ i18n.licenses }}
          </p>
          <h3 class="head-5 accent--above-left fade-in">
            Bio
          </h3>
          <p
            class="fade-in"
            v-html="i18n.bio.brief"
          />
          <div
            v-if="i18n.agentBioModal.content || i18n.video"
            class="btn-group fade-in"
          >
            <dlp-button
              v-if="i18n.agentBioModal.content"
              type="button"
              @click="showModal('AgentModal', $event)"
            >
              {{ i18n.agentBioModal.buttonText }}
            </dlp-button>
            <dlp-button
              v-if="i18n.video"
              type="button"
              @click="showModal('TeamVideo', $event)"
            >
              {{ i18n.agentVideoModal.buttonText }}
            </dlp-button>
          </div>
          <dlp-appear-modal
            v-if="i18n.agentBioModal.content"
            ref="AgentModal"
          >
            <h3 class="head-5 accent--above-left mb-8">
              {{ i18n.agentBioModal.heading }}
            </h3>
            <div v-html="i18n.agentBioModal.content" />
          </dlp-appear-modal>
          <dlp-video-modal
            v-if="i18n.video"
            ref="TeamVideo"
            :url="i18n.video"
            :caption="i18n.name"
          />
        </section>

        <section
          v-if="i18n.testimonials.testimonials.length"
          id="agent-testimonials"
        >
          <h3 class="head-5 accent--above-left fade-in">
            {{ i18n.testimonials.heading }}
          </h3>
          <div class="dlp-testimonial__wrapper">
            <dlp-testimonial
              v-for="t in i18n.testimonials.testimonials.slice(0, 3)"
              :key="t.id"
              class="fade-in"
              :score="t.rating"
              :initials="t.initials"
            >
              <q v-html="t.trunk" />
            </dlp-testimonial>
          </div>
          <div class="btn-group">
            <dlp-button
              type="button"
              class="fade-in"
              @click="showModal('TestimonialsModal', $event)"
            >
              {{ i18n.testimonials.modal.buttonText }}
            </dlp-button>
            <dlp-appear-modal ref="TestimonialsModal">
              <h3 class="head-5 accent--above-left">
                {{ i18n.testimonials.modal.heading }}
              </h3>
              <div class="dlp-testimonial__wrapper">
                <dlp-testimonial
                  v-for="t in i18n.testimonials.testimonials"
                  :key="t.id"
                  :score="t.rating"
                  :initials="t.initials"
                >
                  <div v-html="t.quote" />
                </dlp-testimonial>
              </div>
            </dlp-appear-modal>
          </div>
        </section>

        <section
          v-if="i18n.activeListings.listings.listings.length"
          id="agent-listings"
        >
          <h3 class="head-5 accent--above-left fade-in">
            {{ i18n.activeListings.heading }}
          </h3>
          <div class="dlp-property__wrapper">
            <dlp-property
              v-for="s in i18n.activeListings.listings.listings.slice(0, 3)"
              :key="`brief-${s.id}`"
              class="fade-in"
              :sm="s.photo.sm"
              :lg="s.photo.lg"
              :price="s.price"
              :address="s.address"
            />
          </div>
          <div class="sales-display-stats">
            DISPLAYING
            {{ i18n.activeListings.listings.listings.slice(0, 3).length }}<span v-if="i18n.activeListings.listings.total >= i18n.activeListings.listings.listings.slice(0, 3).length">/{{ i18n.activeListings.listings.total }}</span>
            ACTIVE LISTINGS
          </div>

          <div class="btn-group">
            <dlp-button
              type="button"
              class="fade-in"
              @click="showModal('ListingsModal', $event)"
            >
              {{ i18n.activeListings.modal.buttonText }}
            </dlp-button>
            <dlp-appear-modal ref="ListingsModal">
              <h3 class="head-5 accent--above-left mb-8">
                {{ i18n.activeListings.modal.heading }}
              </h3>
              <div class="dlp-property__wrapper">
                <dlp-property
                  v-for="sale in i18n.activeListings.listings.listings"
                  :key="sale.id"
                  :sm="sale.photo.sm"
                  :lg="sale.photo.lg"
                  :price="sale.price"
                  :address="sale.address"
                />
              </div>
              <div class="sales-display-stats" >
                DISPLAYING
                {{ i18n.activeListings.listings.listings.length }}<span v-if="i18n.activeListings.listings.total >= i18n.activeListings.listings.listings.length">/{{ i18n.activeListings.listings.total }}</span>
                ACTIVE LISTINGS
              </div>
            </dlp-appear-modal>
          </div>
        </section>

        <section
          v-if="i18n.sales.sales.recentSales.length"
          id="agent-sales"
        >
          <h3 class="head-5 accent--above-left fade-in">
            {{ i18n.sales.heading }}
          </h3>
          <div class="dlp-property__wrapper">
            <dlp-property
              v-for="s in i18n.sales.sales.recentSales.slice(0, 3)"
              :key="`brief-${s.id}`"
              class="fade-in"
              :sm="s.photo.sm"
              :lg="s.photo.lg"
              :price="s.price"
              :address="s.address"
            />
          </div>
          <div class="sales-display-stats">
            DISPLAYING
            {{ i18n.sales.sales.recentSales.slice(0, 3).length }}<span v-if="i18n.sales.sales.total >= i18n.sales.sales.recentSales.slice(0, 3).length">/{{ i18n.sales.sales.total }}</span>
            RECENT SALES
          </div>

          <div class="btn-group">
            <dlp-button
              type="button"
              class="fade-in"
              @click="showModal('SalesModal', $event)"
            >
              {{ i18n.sales.modal.buttonText }}
            </dlp-button>
            <dlp-appear-modal ref="SalesModal">
              <h3 class="head-5 accent--above-left mb-8">
                {{ i18n.sales.modal.heading }}
              </h3>
              <div class="dlp-property__wrapper">
                <dlp-property
                  v-for="sale in i18n.sales.sales.recentSales"
                  :key="sale.id"
                  :sm="sale.photo.sm"
                  :lg="sale.photo.lg"
                  :price="sale.price"
                  :address="sale.address"
                />
              </div>
              <div class="sales-display-stats">
                DISPLAYING
                {{ i18n.sales.sales.recentSales.length }}<span v-if="i18n.sales.sales.total >= i18n.sales.sales.recentSales.length">/{{ i18n.sales.sales.total }}</span>
                RECENT SALES
              </div>
            </dlp-appear-modal>
          </div>
        </section>

        <section
          v-if="i18n.stats.length"
          id="agent-stats"
        >
          <h3 class="head-5 accent--above-left fade-in">
            {{ i18n.statsHeading }}
          </h3>
          <div class="space-y-4 lg:space-y-6 mt-6 lg:mt-8">
            <div
              v-for="stat in i18n.stats"
              :key="stat.id"
              class="stat fade-in"
            >
              <span
                class="head-3 text-accent-base"
                v-html="stat['span-1']"
              />
              <span
                class="head-6"
                v-html="stat['span-2']"
              />
            </div>
          </div>
        </section>
        <section id="agent-contact">
          <h3 class="head-5 accent--above-left fade-in">
            {{ i18n.contact.heading }}
          </h3>
          <address class="contact-info fade-in">
            <a :href="`tel:+1-${i18n.contact.phone}`">{{ i18n.contact.phone }}</a>
            <a :href="`mailto:${i18n.contact.email}`">{{ i18n.contact.email }}</a>
          </address>
          <dlp-social
            class="fade-in"
            v-bind="i18n.contact.social"
          />

          <div v-if="i18n.showOfficeInfo">
            <h5 class="head-5 mt-4">
              Office Location
            </h5>
            <p
              class="mt-0"
              v-text="streetAddress"
            />
            <p
              class="mt-0"
              v-text="cityStateZip"
            />
            <a
              class="mt-0"
              :href="`tel:+1-${i18n.officePhone}`"
            >
              <p class="mt-0">{{ i18n.officePhone }}</p>
            </a>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AgentContainer',
  data() {
    return {
      publicPath: process.env.BASE_URL,
      i18n: window.appData.agent,
    };
  },
  computed: {
    streetAddress() {
      return [this.i18n.officeLocation.address1, this.i18n.officeLocation.address2].filter(addressPart => addressPart && addressPart.length).join(', ');
    },
    cityStateZip () {
      const retStr = (this.i18n.officeLocation.city || '')
          + (this.i18n.officeLocation.city && this.i18n.officeLocation.state ? ', ' : '')
          + (this.i18n.officeLocation.state || '')
          + ' '
          + (this.i18n.officeLocation.zip || '');
      return retStr.trim();
    },
  },
  methods: {
    showModal: function(name, evt) {
      if (this.$refs[name]) {
        this.$refs[name].open(evt);
      }
    },
  },
};
</script>
