<template>
    <div class="section-content" v-if="i18n.name">
        <section id="team-bio" class="content-grid">
            <div v-if="i18n.logo.sm" class="col-span-full lg:col-span-14 lg:col-start-2 text-center">
                <img
                    :src="i18n.logo.sm"
                    class="mx-auto logo--agency fade-in"
                    :alt="i18n.logo.alt" />
            </div>
            <div v-if="i18n.teamImage.sm" class="col-span-full px-12 sm:px-0 sm:col-span-2 sm:col-start-2 md:col-span-4 lg:col-start-2 lg:col-span-5">
                <dlp-figure 
                    :sm="i18n.teamImage.sm"
                    :lg="i18n.teamImage.lg"
                    :alt="i18n.teamImage.alt"
                    ar="ar ar--4-5"
                    :isSticky="false"
                    animate="shrink"
                />
            </div>
            <div class="col-span-full md:col-span-7 lg:col-start-9 lg:col-span-7">
                <div>
                    <h1 class="head-1 fade-in">{{i18n.name}}</h1>
                    <h2 class="head-5 accent--above-left fade-in">Team Bio</h2>
                    <p class="fade-in" v-html="i18n.bio.brief"></p>
                    <div class="btn-group fade-in">
                        <dlp-button type="button" @click="showModal(i18n.teamBioModal.type, $event)">{{ i18n.teamBioModal.buttonText }}</dlp-button>
                        <dlp-button v-if="i18n.video" type="button" @click="showModal(i18n.teamVideoModal.type, $event)">{{ i18n.teamVideoModal.buttonText }}</dlp-button>
                    </div>
                    <dlp-appear-modal ref="AgentModal">
                      <h3 class="head-5 accent--above-left mb-8">{{ i18n.teamBioModal.heading }}</h3>
                      <div v-html="i18n.teamBioModal.content"></div>
                    </dlp-appear-modal>                        
                    <dlp-video-modal ref="TeamVideo" :url="i18n.video" :caption="i18n.name"/>
                </div>
            </div>
        </section>

        <section id="team-stats" class="content-grid" v-if="i18n.stats.length">
            <div class="col-span-full lg:col-span-14 lg:col-start-2">
                <div class="grid md:grid-cols-3 border-t border-b border-primary-100 divide-y md:divide-y-0 md:divide-x divide-primary-100">
                    <dlp-transition type="fadeIn pop" :delay="0">
                        <div class="p-8 text-center stat">
                            <span class="head-3 block text-accent-base" v-html="i18n.stats[0]['span-1']"></span>
                            <span class="head-6" v-html="i18n.stats[0]['span-2']"></span>
                        </div>
                    </dlp-transition>
                    <dlp-transition type="fadeIn pop" :delay="0.25" v-if="i18n.stats.length > 1">
                        <div class="p-8 text-center stat">
                            <span class="head-3 block text-accent-base" v-html="i18n.stats[1]['span-1']"></span>
                            <span class="head-6" v-html="i18n.stats[1]['span-2']"></span>
                        </div>
                    </dlp-transition>
                    <dlp-transition type="fadeIn pop" :delay="0.5" v-if="i18n.stats.length > 2">
                        <div class="p-8 text-center stat">
                            <span class="head-3 block text-accent-base" v-html="i18n.stats[2]['span-1']"></span>
                            <span class="head-6" v-html="i18n.stats[2]['span-2']"></span>
                        </div>
                    </dlp-transition>
                </div>
            </div>
        </section>

        <section id="team-members" class="content-grid" v-if="i18n.members.length">
          <div class="col-span-full lg:col-span-14 lg:col-start-2 text-center">
            <h2 class="head-5 text-center accent--below-center accent--mb-0">Team Members</h2>
          </div>
          <div class="col-span-full lg:col-span-14 lg:col-start-2 text-center">
            <dlp-carousel
                :options="{
                    spaceBetween: 32,
                }"
                :hasScrollNav="true">

                <swiper-slide v-for="member in i18n.members" :key="member.id">
                  <dlp-bio-card :source="member.photo" :name="member.name" :caption="member.caption" ar="ar--3-4"/>
                </swiper-slide>
            </dlp-carousel>
          </div>
        </section>
    </div>
</template>
<script>

export default {
    name: 'teamContainer',
    data(){
        return {
            publicPath: process.env.BASE_URL,
            i18n: window.appData.team,
        }
    },
    methods: {
        showModal: function(name, evt) {
            if(this.$refs[name]){
                this.$refs[name].open(evt)
            }
        }
    }
}
</script>
