<template>
    <div class="section-content">

        <section class="content-grid" v-if="i18n.milestone.active">
            <div class="col-span-4 md:col-span-6 md:col-start-1 lg:col-span-7 lg:col-start-2 self-center">
              <dlp-figure
                  :sm="i18n.milestone.image"
                  :alt="i18n.milestone.alt"
                  animate="shrink"
                  ar="ar--4-3"
              />
            </div>
            <div class="col-span-4 md:col-span-6 md:col-start-7 lg:col-span-6 lg:col-start-10 self-center">
                <h3 class="head-5 accent--above-left fade-in">{{ i18n.milestone.heading }}</h3>
                <p class="fade-in">{{ i18n.milestone.content }}</p>
                <div class="btn-group fade-in" v-if="i18n.milestone.button.active">
                    <dlp-button type="button" @click="openPdf()">{{ i18n.milestone.button.buttonText }}</dlp-button>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
export default {
    name: 'closingContainer',
    data: () => ({
      i18n: window.appData.closing,
    }),
    methods: {
        showModal: function(name, evt) {
            if(this.$refs[name]){
                this.$refs[name].open(evt)
            }
        },
        openPdf: function() {
            window.open(this.i18n.milestone.button.pdf, '_blank');
        }
    }
}
</script>
